<template>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-12'>

            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12 md:col-9'>
                        <h5 class='mb-0 mt-3'><i class='pi pi-check mr-2'></i> Yönetici Listesi</h5>
                    </div>

                    <div class='col-12 md:col-3 text-right'>
                        <a href='#/ManagerUser/Add'>
                            <Button label='Yönetici Ekle' icon='pi pi-plus' class='p-button-danger'></Button>
                        </a>
                    </div>

                </div>


                <DataTable
                    :value='dataList'
                    :paginator='true'
                    class='p-datatable-gridlines'
                    :rows='6'
                    dataKey='id'
                    :rowHover='true'
                    v-model:filters='filters'
                    filterDisplay='menu'
                    :loading='loadingIndicator'
                    :filters='filters'
                    responsiveLayout='scroll'
                    :globalFilterFields="['name','surName','email','gsmNumber','userType','isActive']">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <Button type='button' icon='pi pi-filter-slash' label='Temizle'
                                    class='p-button-outlined mb-2' @click='clearFilter()' />
                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri Bulunamadı.
                    </template>

                    <template #loading>
                        Yönetici Listesi Yükleniyor. Lütfen Bekleyin.
                    </template>


                    <Column header='Adı, Soyadı' filterField='name' :showFilterMatchModes='false' style='width:18rem'>

                        <template #body='{data}'>
                            <img :alt='data.name' :src="'http://image.a1catering.com.tr/' + data.profilePicture"
                                 class='border-circle border-0 border-solid' width='48'
                                 style='vertical-align: middle' />
                            <span style='margin-left: .5em; vertical-align: middle'
                                  class='image-text'>{{ data.fullName }}</span>
                        </template>

                    </Column>

                    <Column field='projects' header='Proje'></Column>

                    <Column field='email' header='E-Posta' style='width:14rem'></Column>

                    <Column field='gsmNumber' header='GSM' style='width:11rem'></Column>

                    <Column header='Oluşturma Tarihi' filterField='insertDate' dataType='date' style='width:11rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.insertDate) }}
                        </template>
                    </Column>

                    <Column field='userType' header='Statü' style='width:11rem'></Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center'
                            style='width:6.5rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;'
                               :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>
                    </Column>

                    <Column header='Düzenle' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary mr-2'
                                    @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import ManagerUserService from '@/services/managerUserService';
import moment from 'moment';
import { checkUserRole } from '../common/commonConstantFunctions';

export default {
    data() {
        return {
            dataList: [],
            filters: null,
            loadingIndicator: true,
            idFrozen: false,
        };
    },
    _managerUserService: null,
    created() {
        checkUserRole(this,'Admin');
        this._managerUserService = new ManagerUserService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        const response = await this._managerUserService.getAllManagerUsers();
        this.dataList = response.data;
        this.loadingIndicator = false;
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'surName': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'email': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'userType': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        edit(item) {
            this.$router.push({ name: 'editManagerUser', params: { managerUserId: item.id } });
        },
    },
};
</script>

<style scoped lang='scss'>

</style>
